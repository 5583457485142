<template>
  <div>
    <div class="crumbs">
      <a href="###">首页</a>
      <label>></label>
      <router-link to="/a-company/bid-project/bid-project-preserve"
        >投标项目维护</router-link
      >
      <label>></label>
      <a href="###">{{ isSubmit ? "编辑投标项目" : "新增投标项目" }}</a>
    </div>
    <div class="member-wrap-content">
      <el-form
        size="small'"
        label-width="150px"
        label-position="left"
        style="width: 500px"
        :model="form"
        :rules="rules"
        ref="form"
      >
        <el-form-item label="标的状态" prop="bidStatus">
          <span v-if="isEdit && isSubmit">{{
            { 0: "投标中", 1: "已中标", 2: "未中标", 4: "中标审核未通过" }[
              form.bidStatus
            ]
          }}</span>
          <el-select
            v-else
            v-model="form.bidStatus"
            placeholder="请选择中标信息"
          >
            <el-option :value="0" label="投标中"></el-option>
            <el-option :value="1" label="已中标"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="招标机构" prop="bidOrg">
          <span v-if="isEdit && isSubmit">{{ form.bidOrg }}</span>
          <el-input
            v-else
            v-model="form.bidOrg"
            placeholder="请输入招标机构"
          ></el-input>
        </el-form-item>

        <el-form-item label="统一社会信用代码" prop="bidCode">
          <span v-if="isEdit && isSubmit">{{ form.bidCode }}</span>
          <template v-else>
            <el-input
              v-model="form.bidCode"
              placeholder="请输入统一社会信用代码"
            ></el-input>
            <p class="form_item_p" style="right: -150px">
              该代码为招标方机构代码
            </p>
          </template>
        </el-form-item>

        <el-form-item label="招标代理机构" prop="purchaseOrg">
          <span v-if="isEdit && isSubmit">{{ form.purchaseOrg }}</span>
          <el-input
            v-else
            v-model="form.purchaseOrg"
            placeholder="请输入招标代理机构"
          ></el-input>
        </el-form-item>

        <el-form-item label="项目名称" prop="name">
          <span v-if="isEdit && isSubmit">{{ form.name }}</span>
          <el-input
            v-else
            v-model="form.name"
            placeholder="请输入项目名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="招标项目网址" prop="scale">
          <span v-if="isEdit && isSubmit">{{ form.scale }}</span>
          <el-input
            v-else
            v-model="form.scale"
            placeholder="请输入招标项目网址"
          ></el-input>
          <!-- <p v-if="isEdit && isSubmit" style="line-height: 40px; width: 600px">
            {{ form.scale }}
          </p>
          <template v-else>
            <el-input
              v-model="form.scale"
              placeholder="请输入项目介绍"
              type="textarea"
              maxlength="120"
              show-word-limit
            ></el-input>
            <p class="form_item_p">（限制120字符内）</p>
          </template> -->
        </el-form-item>

        <el-form-item label="招标金额" prop="bidMoney">
          <span v-if="isEdit && isSubmit"
            >{{ form.bidMoney | currency }} 万元</span
          >
          <template v-else>
            <el-input v-model="form.bidMoney" placeholder="请输入招标金额">
              <div slot="suffix">万元</div>
            </el-input>
            <p class="form_item_p">该金额为上限金额</p>
          </template>
        </el-form-item>

        <el-form-item label="开标时间" prop="bidDate">
          <span v-if="isEdit && isSubmit">{{ form.bidDate }}</span>
          <template v-else>
            <el-input
              v-model="form.bidDate"
              placeholder="请输入开标时间"
              type="date"
            >
            </el-input>
            <p class="form_item_p" style="right: -155px">
              与招标文件时间保持一致
            </p>
          </template>
        </el-form-item>

        <el-form-item label="保证金金额" prop="marginMoney">
          <span v-if="isEdit && isSubmit"
            >{{ form.marginMoney | currency }} 万元</span
          >
          <el-input
            v-else
            v-model="form.marginMoney"
            placeholder="请输入保证金金额"
          >
            <div slot="suffix">万元</div>
          </el-input>
        </el-form-item>

        <input
          ref="upload"
          type="file"
          @change="onSelectFile"
          hidden
          accept=".pdf"
        />

        <el-form-item label="招标文件">
          <template v-if="isEdit && isSubmit">
            <a :href="handleTo(form.bidFile)" target="_blank">{{
              form.bidFile ? "招标文件附件" : "无"
            }}</a>
          </template>
          <template v-else>
            <el-button
              type="text"
              v-model="form.bidFile"
              v-if="form.bidFile || files.bidFileF"
              >{{
                files.bidFileF ? files.bidFileF.name || form.bidFile.name : ""
              }}</el-button
            >
            <el-button type="primary" @click="selectbidFileF">上传</el-button>
          </template>
        </el-form-item>

        <template v-if="form.bidStatus === 1">
          <el-form-item label="中标编号" prop="bidNum">
            <el-input
              v-model="form.bidNum"
              placeholder="请输入中标编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="合同金额" prop="contractMoney">
            <el-input v-model="form.contractMoney" placeholder="请输入合同金额">
              <div slot="suffix">万元</div>
            </el-input>
          </el-form-item>
          <el-form-item label="中标时间" prop="winDate">
            <el-input
              v-model="form.winDate"
              placeholder="请输入中标时间"
              type="date"
            ></el-input>
          </el-form-item>
          <el-form-item label="中标通知书" prop="bidNotice">
            <template>
              <el-button
                type="text"
                v-model="form.bidNotice"
                v-if="form.bidNotice || files.bindNotF"
                >{{
                  files.bindNotF
                    ? files.bindNotF.name || form.bidNotice.name
                    : ""
                }}</el-button
              >
            </template>
            <el-button type="primary" @click="selectBindNotF">上传</el-button>
          </el-form-item>
          <el-form-item label="项目合同">
            <template>
              <el-button
                type="text"
                v-model="form.contract"
                v-if="form.contract || files.contractF"
                >{{
                  files.contractF
                    ? files.contractF.name || form.contract.name
                    : ""
                }}</el-button
              >
            </template>
            <el-button type="primary" @click="selectContractF">上传</el-button>
          </el-form-item>
        </template>
      </el-form>

      <div
        style="display: flex; justify-content: center; box-sizing: border-box"
      >
        <template v-if="!isEdit || !isSubmit">
          <el-button class="ml10" type="primary" @click="addBidProject(0)"
            >保存草稿</el-button
          >
          <el-button class="ml10" type="primary" @click="addBidProject(1)"
            >提交</el-button
          >
        </template>

        <template v-else-if="form.bidStatus === 1 && form.status != 3">
          <el-button
            size="small"
            class="ml10"
            type="primary"
            @click="addBidStatus()"
            >提交</el-button
          >
        </template>

        <template v-else-if="form.bidStatus === 2 && form.status != 3">
          <el-button
            size="small"
            class="ml10"
            type="primary"
            @click="addBidStatus()"
            >提交</el-button
          >
        </template>

        <template
          v-else-if="
            (form.bidStatus === 0 && form.status === 2) ||
            (form.bidStatus === 4 && form.status === 2)
          "
        >
          <el-button
            size="small"
            class="ml10"
            type="primary"
            @click="setBidStatus(1)"
            >标记为已中标</el-button
          >
          <el-button
            size="small"
            class="ml10"
            type="primary"
            @click="setBidStatus(2)"
            >标记为未中标</el-button
          >
        </template>

        <el-button size="small" class="ml10" @click="cancel">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import PurchaseCompanySelect from "@/views/components/purchaseCompanySelect";
import { formatDate } from "@/utils/formatDate";
export default {
  components: {
    PurchaseCompanySelect,
  },
  data() {
    var bidStatus_rule = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择标的状态"));
      } else {
        callback();
      }
    };
    var bidOrg_rule = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入招标机构"));
      } else {
        callback();
      }
    };
    var bidCode_rule = (rule, value, callback) => {
      let re = /[a-zA-Z0-9]{18}/;
      if (value === "") {
        callback(new Error("请输入统一社会信用代码"));
      } else if (value.length === 18 && re.test(value)) {
        callback();
      } else {
        callback(new Error("请输入18位正确的统一社会信用代码"));
      }
    };
    var name_rule = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入项目名称"));
      } else {
        callback();
      }
    };
    var scale_rule = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入项目介绍"));
      } else {
        callback();
      }
    };
    var bidMoney_rule = (rule, value, callback) => {
      if (Number(value) && Number(value) % 1 == 0) {
        callback();
      } else {
        callback(new Error("请输入招标金额(整数型)"));
      }
    };
    var bidDate_rule = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入开标时间"));
      } else {
        callback();
      }
    };
    var marginMoney_rule = (rule, value, callback) => {
      if (Number(value) && Number(value) % 1 == 0) {
        callback();
      } else {
        callback(new Error("请输入保证金金额(整数型)"));
      }
    };
    var bidNum_rule = (rule, value, callback) => {
      let re = /[a-zA-Z0-9_]/;
      if (re.test(value)) {
        callback();
      } else {
        callback(new Error("请输入中标编号"));
      }
    };
    var contractMoney_rule = (rule, value, callback) => {
      if (Number(value) && Number(value) % 1 == 0) {
        callback();
      } else {
        callback(new Error("请输入合同金额(整数型)"));
      }
    };
    var winDate_rule = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入中标时间"));
      } else {
        callback();
      }
    };

    return {
      bidDate: "",
      form: {
        bidOrg: "",
        name: "",
        bidCode: "",
        bidStatus: "",
        bidNum: "",
        scale: "",
        bidMoney: "",
        bidDate: "",
        marginMoney: "",
        contractMoney: "",
        winDate: "",
        purchaseOrg: "",

        bidFile: "",
        bidNotice: "",
        contract: "",

        status: 0,
      },

      rules: {
        bidStatus: [
          {
            trigger: "blur",
            validator: bidStatus_rule,
          },
        ],
        bidOrg: [
          {
            trigger: "blur",
            validator: bidOrg_rule,
          },
        ],
        bidCode: [
          {
            trigger: "blur",
            validator: bidCode_rule,
          },
        ],
        name: [
          {
            trigger: "blur",
            validator: name_rule,
          },
        ],
        scale: [
          {
            trigger: "blur",
            validator: scale_rule,
          },
        ],
        bidMoney: [
          {
            trigger: "blur",
            validator: bidMoney_rule,
          },
        ],
        bidDate: [
          {
            trigger: "blur",
            validator: bidDate_rule,
          },
        ],
        marginMoney: [
          {
            trigger: "blur",
            validator: marginMoney_rule,
          },
        ],
        bidNum: [
          {
            trigger: "blur",
            validator: bidNum_rule,
          },
        ],
        contractMoney: [
          {
            trigger: "blur",
            validator: contractMoney_rule,
          },
        ],
        winDate: [
          {
            trigger: "blur",
            validator: winDate_rule,
          },
        ],

        bidNotice: [
          {
            required: true,
            trigger: "blur",
            message: "请上传中标通知书附件",
          },
        ],
      },

      uploadType: "", //当前上传类型

      files: {
        bindNotF: null, // 中标通知书附件
        contractF: null, // 合同附件
        bidFileF: null,
      },
    };
  },
  computed: {
    isEdit() {
      return this.form.status === 3 ? false : true;
    },
    isSubmit() {
      return this.form.status === 0 ? false : true;
    },
  },
  mounted() {
    this.initForm();
  },
  methods: {
    handleTo(url) {
      return url;
    },
    initForm() {
      let id = this.$route.params.id;
      if (id == 0) {
        this.form = {
          bidOrg: "",
          name: "",
          bidCode: "",
          bidStatus: "",
          bidNum: "",
          scale: "",
          bidMoney: "",
          bidDate: "",
          marginMoney: "",
          contractMoney: "",
          winDate: "",
          purchaseOrg: "",

          bidFile: "",
          bidNotice: "",
          contract: "",

          status: 0,
        };
        this.files = {
          bindNotF: null, // 中标通知书附件
          contractF: null, // 合同附件
          bidFileF: null,
        };
      } else {
        this.getDetail();
      }
    },
    downloadFile(path, filename) {
      let exts = path.split(".");
      let ext = exts[exts.length - 1];
      this.$axios
        .get("/download", {
          params: {
            path: path,
          },
          responseType: "blob",
        })
        .then((res) => {
          let link = document.createElement("a");
          let fileObj = URL.createObjectURL(res);
          link.download = filename + "." + ext;
          link.href = fileObj;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(fileObj);
        });
    },
    getDetail() {
      this.$axios
        .get(`/bidProject/bidProjectDetails/${this.$route.params.id}`)
        .then((res) => {
          if (res.state == 200) {
            let bidProject = res.data.bidProject;
            // console.log(bidProject);
            this.form = {
              name: bidProject.name,
              bidOrg: bidProject.bidOrg,
              bidCode: bidProject.bidCode,
              scale: bidProject.scale,
              bidStatus: bidProject.bidStatus,
              bidNum: bidProject.bidNum,
              marginMoney: bidProject.marginMoney,
              purchaseOrg: bidProject.purchaseOrg,
              contractMoney: bidProject.contractMoney,
              bidDate: formatDate(bidProject.bidDate),
              winDate: formatDate(
                bidProject.winDate ? bidProject.winDate : new Date()
              ),
              bidMoney: bidProject.bidMoney,

              bidFile: bidProject.bidFile,
              bidNotice: bidProject.bidNotice,
              contract: bidProject.contract,

              status: bidProject.status,
            };
            this.fileSplit(bidProject.bidFile);
          } else {
            this.$message.error(res.message);
          }
        });
    },
    fileSplit(name) {
      // this.files.bidFileF = bidProject.bidFile;
      // let index = name.lastIndexOf("/");
      // let new_name = name.substring(index, name.length);
      // console.log(new_name);
      let new_name = name.split("/");
      let bidFile_name = new_name[new_name.length - 1] ? "招标文件附件" : "";
      this.files.bidFileF = {
        name: bidFile_name,
      };
    },
    selectBindNotF() {
      this.uploadType = "bindNotF";
      this.$refs.upload.click();
    },
    selectContractF() {
      this.uploadType = "contractF";
      this.$refs.upload.click();
    },
    selectbidFileF() {
      this.uploadType = "bidFileF";
      this.$refs.upload.click();
    },
    onSelectFile(e) {
      let files = e.target.files;
      if (files.length == 0) return;

      this.files[this.uploadType] = files[0];
      e.target.value = "";
    },
    // status 0 草稿 1 提交
    async addBidProject(status) {
      let _this = this;
      if (_this.form.bidStatus != 1) {
        _this.files.bindNotF = "";
        _this.files.contractF = "";
      }
      _this.form.bidFile = _this.files.bidFileF
        ? _this.files.bidFileF
        : _this.form.bidFile;
      _this.form.bidNotice = _this.files.bindNotF;
      _this.form.contract = _this.files.contractF;
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let url = "/binProject/addBidProject";
          let data = {
            scale: _this.form.scale,
            bidNum: _this.form.bidNum,
            name: _this.form.name,
            bidOrg: _this.form.bidOrg,
            bidCode: _this.form.bidCode,
            bidStatus: _this.form.bidStatus,
            status: status,

            bidMoney: _this.form.bidMoney,
            bidDate: _this.form.bidDate,
            marginMoney: _this.form.marginMoney,
            contractMoney: _this.form.contractMoney,
            winDate: _this.form.winDate,
            purchaseOrg: _this.form.purchaseOrg,

            bidFileF: _this.files.bidFileF,
            bindNotF: _this.files.bindNotF,
            contractF: _this.files.contractF,
          };
          let id = _this.$route.params.id;
          if (id && id > 0) {
            data.id = id;
            url = "/bidProject/updataBidProject";
          }
          // console.log(data);
          let formData = new FormData();
          Object.keys(data).forEach((key) => {
            formData.set(key, data[key]);
          });
          await _this.$axios.post(url, formData).then((res) => {
            if (res.state == 200) {
              this.$message.success(res.message);
              _this.initForm();
              _this.$router.back();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    // 设置为已中标/未中标
    setBidStatus(bidStatus) {
      if (bidStatus === 1) {
        this.form.bidStatus = 1;
      } else {
        this.form.bidStatus = 2;
      }
    },
    async addBidStatus() {
      let _this = this;
      if (_this.form.bidStatus != 1) {
        _this.files.bindNotF = "";
        _this.files.contractF = "";
      }
      _this.form.bidNotice = _this.files.bindNotF;
      _this.form.contract = _this.files.contractF;
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let url = "/bidProject/signBidProject";
          let data = {};
          if (_this.form.bidStatus == 1) {
            data = {
              id: _this.$route.params.id,
              bidStatus: _this.form.bidStatus,
              bindNotF: _this.files.bindNotF,
              contractF: _this.files.contractF,
              contractMoney: _this.form.contractMoney,
              winDate: _this.form.winDate,
              bidNum: _this.form.bidNum,
            };
          } else {
            data = {
              id: _this.$route.params.id,
              bidStatus: _this.form.bidStatus,
            };
          }

          var formData = new FormData();
          Object.keys(data).forEach((key) => {
            formData.set(key, data[key]);
          });
          await _this.$axios.post(url, formData).then((res) => {
            if (res.state == 200) {
              this.$message.success(res.message);
              _this.$router.back();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    cancel() {
      this.$router.back();
    },
    handleTo(url) {
      return url;
    },
  },
};
</script>
<style lang="scss" scoped>
.member-wrap-content {
  width: 941px;
  border: 1px solid #ededed;
  padding: 64px;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.ml10 {
  margin-left: 10px;
}
.form_item_p {
  color: #aaa;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: -120px;
}
span.el-input__count {
  height: 20px;
  line-height: 20px;
}
a {
  text-decoration: none;
  color: #3883ff;
  margin-right: 20px;
}
</style>